import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private authStateSubject = new BehaviorSubject<boolean>(false);
  public authState$ = this.authStateSubject.asObservable();
  public getMsg$ = this.authStateSubject.asObservable();

  updateAuthState(isAuthenticated: boolean) {
    this.authStateSubject.next(isAuthenticated);
  }

  clearSession() {
    this.authStateSubject.next(false);
  }

  
} 