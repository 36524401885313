import {lyl, StyleRenderer, ThemeRef, ThemeVariables} from '@alyle/ui';
import {Component, inject, OnDestroy} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';

import {LoadingInterface, LoadingService} from './shared-module/services/loading.service';
import {HTTPService} from './shared-module/services/main.service';
import {EventsService} from "./shared-module/services/events.service";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
//
const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl`{
      display: block
    }`,
  };
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [StyleRenderer],
})
export class AppComponent implements OnDestroy {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);

  title = "BINDL Партнер";
  private events = inject(EventsService);
  private toastr = inject(ToastrService)
  private destroy$ = new Subject();

  Loadings: LoadingInterface[] = [];

  EnvDesc = environment.backendURL;
  IsProduction = environment.production;

  constructor(
    readonly sRenderer: StyleRenderer,
    private main: HTTPService,
    private router: Router,
    public loadingService: LoadingService,
    public sanitizer: DomSanitizer
  ) {
    this.InitToastr();
    let rs = router.events.subscribe((url: any) => {
      if (url.url && url.url != undefined && url.url.split("/")[1] == "entry") {
        rs.unsubscribe();
      } else {
        rs.unsubscribe();
        this.main.CheckToken();
        this.main.IsLoadingChange$.subscribe((res) => {
        });
      }
    });

    this.loadingService.IsLoadingChange$.subscribe((res) => {
      this.Loadings = this.loadingService.Loadings;
    });
  }

  InitToastr() {
    this.events.IsErrorChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res && this.events.Error.status) {
          this.toastr.error(this.events.Error.text, "Ошибка!", {
            positionClass: "toast-bottom-center",
          });
        } else {
          this.toastr.clear();
        }
      })

    this.events.IsSuccessChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res && this.events.Success.status) {
          this.toastr.success(
            this.events.Success.text,
            "Операция выполнена успешно",
            {
              positionClass: "toast-bottom-center",
              enableHtml: true
            }
          );
        } else {
          this.toastr.clear();
        }
      })

    this.events.IsProccessingChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res && this.events.Proccessing.status) {
          this.toastr.info(
            this.events.Proccessing.text,
            "Информация",
            {
              positionClass: "toast-bottom-center",
            }
          );
        } else {
          this.toastr.clear();
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}




