import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum LoadingStates {
  UNKNOWN = "UNKNOWN",
  MAIN = "MAIN",
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_REGISTER = "AUTH_REGISTER",
  AUTH_VALIDATE = "AUTH_VALIDATE",
  DASHBOARD_DATA = "DASHBOARD_DATA",
  CARDS_LIST = "CARDS_LIST",
  CARD_REFERRAL = "CARD_REFERRAL",
  RESET_PASSWORD = "RESET_PASSWORD",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SETTINGS = "SETTINGS",
  INVOICE = "INVOICE",
  OPERATOR_LIST = "OPERATOR_LIST",
  OPERATOR_FIND = "OPERATOR_FIND",
  REGISTER_OPERATOR = "OPERATOR_FIND",
  OPERATOR_PAGE = "OPERATOR_PAGE",
  OPERATOR_UPDATE = "OPERATOR_UPDATE",
  FETCH_USER_PURCHASE_HISTORY = "FETCH_USER_PURCHASE_HISTORY",
  FETCH_BUSINESS = "FETCH_BUSINESS",
  FETCH_FEEDBACKS = "FETCH_FEEDBACKS",
  UPLOAD_BUSINESS = "UPLOAD_BUSINESS",
  FETCH_BUSINESS_DEVICES = "FETCH_BUSINESS_DEVICES",
  CREATE_BUSINESS_DEVICES = "CREATE_BUSINESS_DEVICES",
  CLIENTS_LIST = "CLIENTS_LIST",
  SEND_PUSH = "SEND_PUSH",
  HISTORY_PUSH = "HISTORY_PUSH",
  GEO_CONTENT = "GEO_CONTENT",
  CLIENT_INFO = "CLIENT_INFO",
  CLIENT_PUSH_HIST = "CLIENT_PUSH_HIST",
  CLIENT_REFERRED = "CLIENT_REFERRED",
  TAB_CONTENT = "TAB_CONTENT",
  BONUS_ACTION = "BONUS_ACTION",
  LEVEL_ACTION = "LEVEL_ACTION",
  CARD_LIST = "CARD_LIST",
  STEP_STATUS = "STEP_STATUS",
  GEO_CREATE = "GEO_CREATE",
  GEO_DELETE = "GEO_DELETE",
  CLIENT_PAGE = "CLIENT_PAGE",
  RELEASE_CARD_GENERATE_LINK = "RELEASE_CARD_GENERATE_LINK",
  TRIGGER_PAGE = "TRIGGER_PAGE",
  DOWNLOAD = "DOWNLOAD",
  GROUP__PAGE = "GROUP__PAGE",
  UNGROUP__PAGE = "UNGROUP__PAGE",
  CLASS__LIST = "CLASS__LIST",
  CLASS__PRODUCTS = "CLASS__PRODUCTS",
  ADD_PRODUCTS__LIST = 'ADD_PRODUCTS__LIST',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  ADD_GROUP = 'ADD_GROUP',
  GROUP_LIST = 'GROUP_LIST',
  GROUP_ITEM = 'GROUP_ITEM',
  GET_CATEGORY_LIST = 'GET_CATEGORY_LIST',
  GET_POSITION_LIST = 'GET_POSITION_LIST',
  GET_POSITION_ITEM = 'GET_POSITION_ITEM',
  SAVE_POSITION_ITEM = 'SAVE_POSITION_ITEM',
  CONFIRM = 'CONFIRM',
  CREATE_CATEGORY = "CREATE_CATEGORY",
  EDIT_CATEGORY = "EDIT_CATEGORY",
  SAVE_MENU_INFO = 'SAVE_MENU_INFO',
  GET_MENU_INFO = 'GET_MENU_INFO',
  SET_BOT_TOKEN = 'SET_BOT_TOKEN',
  GET_BOT_TOKEN = 'GET_BOT_TOKEN',
  GET_ORDERS = 'GET_ORDERS',
  GET_ORDER = 'GET_ORDER',
  CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS',
  CHANGE_ORDER = 'CHANGE_ORDER',
  GET_HISTORY_EVENTS = 'GET_HISTORY_EVENTS',
  NEW_LINK = 'NEW_LINK',
  GET_LINKS = 'GET_LINKS',
  DELETE_LINKS = 'DELETE_LINKS',
  CHANGE_ESTABLISHMENT = "CHANGE_ESTABLISHMENT",
  JURASTIC_LIST = "JURASTIC_LIST",
  JURASTIC_ADD = "JURASTIC_ADD",
  GET_TIP_PERCENT = "GET_TIP_PERCENT",
  EDIT_TIP_PERCENT = "EDIT_TIP_PERCENT",
  POST_TIP_PERCENT = "POST_TIP_PERCENT",
  GET_PARTNER_PAYMENT_HISTORI = "GET_PARTNER_PAYMENT_HISTORI",
  GET_PRODUCT_LIST = "GET_PRODUCT_LIST",
  POST_CODE = "POST_CODE",
  GET_LOYALTY = "GET_LOYALTY",
  SET_LOYALTY = "SET_LOYALTY",
  GET_COMPANY_INFO = "GET_COMPANY_INFO",
  SET_COMPANY_INFO = "SET_COMPANY_INFO",
  EDIT_LOYALTY_LEVEL = "EDIT_LOYALTY_LEVEL",
  PARTNER_PRODUCT_STATS = "PARTNER_PRODUCT_STATS",
  PARTNER_PRODUCT_GROUP = "PARTNER_PRODUCT_GROUP",
  PARTNER_PRODUCT_EXCEPTION = "PARTNER_PRODUCT_EXCEPTION",
  GET_PRODUCT_WITHOUT = "GET_PRODUCT_WITHOUT",
  EDIT_GROUP = "EDIT_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  ADD_PRODUCT_TO_GROUP = "ADD_PRODUCT_TO_GROUP",
  ADD_PRODUCT_TO_GROUP_CLASSIFIER = "ADD_PRODUCT_TO_GROUP_CLASSIFIER",
  DELETE_PRODUCT_TO_GROUP = "DELETE_PRODUCT_TO_GROUP",
  DELETE_EXCEPTION = "DELETE_EXCEPTION",
  EDIT_EXCEPTION = "EDIT_EXCEPTION",
  GET_PRODUCT_WITHOUT_EXCEPTION = "GET_PRODUCT_WITHOUT_EXCEPTION",
  ADD_PRODUCT_EXCEPTION = "ADD_PRODUCT_EXCEPTION",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  GET_TABLE_LIST = "GET_TABLE_LIST",
  CREATE_TABLE = "CREATE_TABLE",
  EDIT_TABLE = "EDIT_TABLE",
  GET_QR_TABLE = "GET_QR_TABLE",
  ADS_LIST = "ADS_LIST",
  ADS_CREATE = "ADS_CREATE",
  ADS_DELETE = "ADS_DELETE",
}

export interface LoadingInterface {
  id: string;
  type: string;
}

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor() {
  }

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map((x) => {
    return {state: x, value: false};
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {
    //console.log("\n\n")
    //console.log("STATES before adding = ", Object.assign({},this.States));

    const id = this.uuidv4();
    this.Loadings.push({
      id,
      type: requestType,
    });

    // console.warn( this.States, requestType);
    this.States.find((x) => x.state == requestType).value = true;

    //console.log("STATES afer adding = ", Object.assign({},this.States));
    //console.log("=== > Current Loadings { add } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    //console.log("\n\n")
    //console.log("STATES before deleting = ", Object.assign({},this.States))
    const type = this.Loadings.find((x) => x.id == id).type;
    this.Loadings = this.Loadings.filter((f) => f.id != id);

    let countCurrentType = this.Loadings.filter((x) => x.type == type).length;
    this.States.find((x) => x.state == type).value =
      countCurrentType > 0 ? true : false;

    //console.log("STATES afer deleting = ", Object.assign({},this.States))
    //console.log("=== > Current Loadings { remove } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex((x) => x.type == requestType) > -1
      ? true
      : false;
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex((x) => x.state == type);
  }
}
